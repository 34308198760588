import { defineStore } from "pinia";
import { api } from "@/services/api";
import axios from "axios";

export const useStreamsStore = defineStore("streams", {
  // convert to a function
  state: () => ({
    streamlist: [] as any,
    schedulelist: [] as any,
  }),
  getters: {
    streams(state) {
      return state.streamlist;
    },
    schedules(state) {
      return state.schedulelist;
    },
  },
  actions: {
    // no context as first argument, use `this` instead
    getStreams() {
      return new Promise((resolve, reject) => {
        axios
          .get(api.streams)
          .then(({ data }) => {
            this.streamlist = data.rm_streams.items;
            this.streamlist.push({
              id: "jc",
              name: "JC Spielwiese",
              stream_categories: [],
              genres: "JC Spielwiese",
              tunein_urls: {
                mp3: "http://Moderator.rautemusik.fm:40000/rmms2",
                aacp: "http://Moderator.rautemusik.fm:40000/rmms2",
              },
              picture: {
                // url: require("@/assets/jc-placeholder.png"),
              },
              has_team: false,
            });
            this.streamlist.sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            });
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getSchedules() {
      return new Promise((resolve, reject) => {
        axios
          .get(api.schedule)
          .then(({ data }) => {
            this.schedulelist = data;
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    // easily reset state using `$reset`
    clearStreams() {
      this.$reset();
    },
  },
});
