
import {
  IonButtons,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonText,
  IonCard,
  IonList,
  IonNavLink,
  IonItem,
  IonLabel,
  IonButton,
  IonCheckbox,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, PropType, ref } from "vue";
import { Clip, Bett } from "@/services/interfaces";
import PushModalSummary from "./PushModalSummary.vue";
import { computed } from "@vue/reactivity";

export default defineComponent({
  name: "PushModalSender",
  props: {
    bett: Object as PropType<Bett>,
    clip: Object as PropType<Clip>,
    schedule: Object as PropType<any>,
  },
  components: {
    IonButtons,
    IonHeader,
    IonContent,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonText,
    IonCard,
    IonList,
    IonNavLink,
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox,
    IonBackButton,
  },
  setup() {
    const senderItems = ref([
      { id: "rock", name: "Rock", selected: false },
      { id: "pop", name: "Pop", selected: false },
      { id: "club", name: "Club", selected: false },
      { id: "rnb", name: "R&B", selected: false },
      { id: "schlager", name: "Schlager", selected: false },
    ]);

    const selectedSender = computed(() => {
      return senderItems.value.filter((x) => x.selected);
    });
    return { PushModalSummary, senderItems, selectedSender };
  },
});
