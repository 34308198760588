import { Bett } from "@/services/interfaces";

const base_url =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/"
    : process.env.VUE_APP_URL_BASE;

export const api = {
  login: base_url + process.env.VUE_APP_URL_LOGIN,
  logout: base_url + process.env.VUE_APP_URL_LOGOUT,
  refresh: base_url + process.env.VUE_APP_URL_REFRESH,
  user: base_url + process.env.VUE_APP_URL_USER,
  auth: base_url + process.env.VUE_APP_URL_AUTH,
  streams: base_url + process.env.VUE_APP_URL_STREAMS,
  schedule: base_url + process.env.VUE_APP_URL_SCHEDULE,
  proxy: base_url + process.env.VUE_APP_URL_PROXY,
  socket: process.env.VUE_APP_URL_SOCKET,
};

export const betten: Array<Bett> = [
  {
    id: 0,
    text: "Kein Bett",
  },
  {
    id: 1,
    text: "Standard",
  },
  {
    id: 2,
    text: "Alternativ",
  },
  {
    id: 3,
    text: "Langsam",
  },
];
