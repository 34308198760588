import { Clip } from "@/services/interfaces";
import dayjs from "dayjs";

export function mapRange(
  current: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number {
  const mapped: number =
    ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  return mapped;
}

export function clipdate(clip: Clip | undefined) {
  if (!clip) return "-";
  const timestamp = dayjs(clip.id, "YYYY-MM-DD-HH-mm-ss");
  return timestamp.calendar(dayjs());
}
export function clipduration(clip: Clip | undefined) {
  if (!clip) return "-";
  const minutes = Math.floor(clip.duration / 60);
  const seconds = clip.duration - minutes * 60;
  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0")
  );
}
