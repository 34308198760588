
import {
  IonButtons,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonText,
  IonCard,
  IonList,
  IonNavLink,
  IonItem,
  IonLabel,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import { Clip } from "@/services/interfaces";
import { betten } from "@/services/api";
import PushModalSender from "./PushModalSender.vue";

export default defineComponent({
  name: "PushModalBett",
  props: {
    clip: Object as PropType<Clip>,
    schedule: Object as PropType<any>,
  },
  components: {
    IonButtons,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonText,
    IonCard,
    IonList,
    IonNavLink,
    IonItem,
    IonLabel,
    IonBackButton,
  },
  setup() {
    return { betten, PushModalSender };
  },
});
