
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonIcon,
  IonLabel,
  IonNavLink,
  IonList,
  IonCard,
  IonText,
  modalController,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import { Clip } from "@/services/interfaces";
import PushModalBett from "./PushModalBett.vue";
import PushModalPlan from "./PushModalPlan.vue";
import { timeOutline, walletOutline } from "ionicons/icons";

export default defineComponent({
  name: "PushModalTime",
  props: {
    clip: Object as PropType<Clip>,
  },
  components: {
    IonButtons,
    IonButton,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonIcon,
    IonLabel,
    IonList,
    IonCard,
    IonText,
    IonNavLink,
  },
  setup() {
    function cancel() {
      return modalController.dismiss(null, "cancel");
    }
    return {
      cancel,
      PushModalBett,
      PushModalPlan,
      timeOutline,
      walletOutline,
    };
  },
});
