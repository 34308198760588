import { toastController } from "@ionic/vue";

export const toast = (
  options: import("@ionic/core/components").ToastOptions
): void => {
  toastController
    .create({
      duration: 1500,
      ...options,
    })
    .then((x) => x.present());
};
