import { defineStore } from "pinia";
import { api } from "@/services/api";
import axios from "axios";

export const useUserStore = defineStore("user", {
  // convert to a function
  state: () => ({
    birthdate: "",
    gender: "",
    name: "",
    nickname: "",
    rm_user_id: "",
    preferred_username: "",
    given_name: "",
    family_name: "",
    picture: "",
    auth: "" as any,
  }),
  getters: {
    user(state) {
      return state;
    },
  },
  actions: {
    // no context as first argument, use `this` instead
    getUser() {
      return new Promise((resolve, reject) => {
        axios
          .get(api.user, { withCredentials: true })
          .then(({ data }) => {
            this.birthdate = data.birthdate;
            this.gender = data.gender;
            this.name = data.name;
            this.nickname = data.nickname;
            this.rm_user_id = data.rm_user_id;
            this.preferred_username = data.preferred_username;
            this.given_name = data.given_name;
            this.family_name = data.family_name;
            this.picture = data.picture;
            //console.log(this.nickname);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getUserAuth() {
      return new Promise((resolve, reject) => {
        axios
          .get(api.auth + this.rm_user_id)
          .then(({ data }) => {
            this.auth = data.user;
            //console.log(data.user);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    logoutUser() {
      axios
        .get(api.logout, { withCredentials: true })
        .then(() => {
          this.clearUser();
          location.reload();
        })
        .catch(() => {
          this.clearUser();
          location.reload();
        })
        .finally(() => {
          this.clearUser();
          location.reload();
        });
    },
    // easily reset state using `$reset`
    clearUser() {
      this.$reset();
    },
  },
});
