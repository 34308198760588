import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import TakesPage from "../views/TakesPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/takes",
  },
  {
    path: "/takes",
    component: TakesPage,
  },
  {
    path: "/settings",
    component: () => import("@/views/SettingsPage.vue"),
  },
  {
    path: "/plan",
    component: () => import("@/views/PlanPage.vue"),
  },
  {
    path: "/push",
    component: () => import("@/views/PushPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
