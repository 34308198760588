
import {
  IonButtons,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonList,
  IonNavLink,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonButton,
  IonCheckbox,
  IonBackButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, PropType, ref } from "vue";
import { Clip, Bett } from "@/services/interfaces";
import { checkboxOutline } from "ionicons/icons";
import PushModalBett from "./PushModalBett.vue";
import { computed } from "@vue/reactivity";

import { useStreamsStore } from "@/stores/streams";

export default defineComponent({
  name: "PushModalSender",
  props: {
    bett: Object as PropType<Bett>,
    clip: Object as PropType<Clip>,
  },
  components: {
    IonButtons,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonNavLink,
    IonList,
    IonItem,
    IonLabel,
    IonBackButton,
    IonThumbnail,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon,
  },
  setup() {
    const streamsStore = useStreamsStore();

    return { PushModalBett, streamsStore, checkboxOutline };
  },
});
