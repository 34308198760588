import { Drivers, Storage } from "@ionic/storage";

// const storage = new Storage({
//   name: "mod2go",
//   driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
// });
// await storage.create();

// export default storage;

export function useStorage() {
  const storage = new Storage({
    name: "mod2go",
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
  });
  storage.create();
  return {
    storage,
  };
}
