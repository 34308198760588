import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d080fdfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clip-item-info" }
const _hoisted_2 = {
  key: 0,
  class: "clip-more"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ion-flex ion-justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!

  return (_openBlock(), _createBlock(_component_ion_item_sliding, { class: "clip-item" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, {
        button: "",
        detail: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAudio = _ctx.showAudio ? null : _ctx.clip?.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-between" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("b", null, _toDisplayString(_ctx.clip?.name), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_text, { color: "medium" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.clipdate(_ctx.clip)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_ion_note, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.clipduration(_ctx.clip)), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "slide-fade" }, {
                default: _withCtx(() => [
                  (_ctx.showAudio)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("audio", {
                          src: _ctx.clip?.audio,
                          controls: "",
                          preload: "auto",
                          class: "clip-audio"
                        }, null, 8, _hoisted_3),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_ion_button, {
                            fill: "outline",
                            onClick: _withModifiers(_ctx.presentClipOptions, ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.ellipsisHorizontalOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_ion_button, {
                            onClick: _withModifiers(_ctx.presentPushModal, ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.cloudUploadOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item_options, { side: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item_option, {
            color: "medium",
            onClick: _ctx.presentClipOptions
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.ellipsisHorizontalOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_ion_item_option, {
            color: "primary",
            onClick: _ctx.presentPushModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.cloudUploadOutline
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}