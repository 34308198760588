import { defineStore } from "pinia";
import { useStorage } from "./storageService";
import { useUserStore } from "./user";
import { Clip } from "@/services/interfaces";

const { storage } = useStorage();

export const useClipsStore = defineStore("clips", {
  state: () => ({
    cliplist: [] as Clip[],
  }),
  getters: {
    clips(state) {
      return state.cliplist;
    },
  },
  actions: {
    async dataURLtoFile(dataurl: string) {
      const arr = dataurl.split(",");
      let mime = arr[0] as any;
      if (mime) {
        mime = mime.match(/:(.*?);/)[1];
      }

      const res = await fetch(dataurl);
      const buf = await res.arrayBuffer();
      return new File([buf], "clip", { type: mime });
    },
    deleteClip(clip: Clip) {
      return new Promise((resolve, reject) => {
        storage
          .remove(clip.id)
          .then(() => {
            //console.log("Deleted", clip.id);
            resolve(clip);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getClips() {
      return new Promise((resolve, reject) => {
        this.cliplist = [];
        // const userStore = useUserStore();
        storage
          .forEach((value: Clip | any) => {
            // if (userStore.rm_user_id == value.user) {
            this.cliplist.unshift(value);
            // }
            // console.log(value);
          })
          .then(() => {
            //console.log("Iteration has completed");
            resolve(this.cliplist);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    saveClip(clip: Clip) {
      delete clip.hidden;
      return new Promise((resolve, reject) => {
        const userStore = useUserStore();
        clip = { ...clip, user: userStore.rm_user_id };
        storage
          .set(clip.id, clip)
          .then(() => {
            resolve(clip);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
});
