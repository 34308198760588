
import {
  IonButtons,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonNote,
  IonCard,
  IonButton,
  IonItem,
  IonLabel,
  IonChip,
  IonIcon,
  IonBackButton,
  IonRow,
  IonText,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import { Clip, Bett, Sender } from "@/services/interfaces";
import { timeOutline, walletOutline } from "ionicons/icons";
import dayjs from "dayjs";
import { clipdate, clipduration } from "@/utils/helpers";

export default defineComponent({
  name: "PushModalSummary",
  props: {
    bett: Object as PropType<Bett>,
    clip: Object as PropType<Clip>,
    selectedSender: Array as PropType<Array<Sender>>,
    schedule: Object as PropType<any>,
  },
  components: {
    IonButtons,
    IonHeader,
    IonContent,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonNote,
    IonCard,
    IonButton,
    IonBackButton,
    IonItem,
    IonLabel,
    IonChip,
    IonIcon,
    IonRow,
    IonText,
  },
  setup() {
    return { timeOutline, walletOutline, dayjs, clipdate, clipduration };
  },
});
