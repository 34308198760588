
import { IonSelect, IonSelectOption } from "@ionic/vue";
import { defineComponent, reactive, ref, computed } from "vue";
import { toast } from "@/services/toast";
import { mapRange } from "@/utils/helpers";

import { useClipsStore } from "@/stores/clips";

import dayjs from "dayjs";

export default defineComponent({
  name: "RecordSheet",
  props: {
    name: String,
  },
  components: { IonSelect, IonSelectOption },
  setup() {
    const sender = ref("pop");
    const mediaRecorder = ref<MediaRecorder>();
    const mediaStream = ref<MediaStream>();
    const recorder = reactive({
      recording: false,
      duration: 0,
      interval: 0,
      options: {
        mimeType: "audio/webm",
        audioBitsPerSecond: 128000,
      },
    });
    const canvasInterval = ref<number>(0);
    const loudnessWaveform = ref<Array<number>>([]);

    const clipsStore = useClipsStore();

    const clipsCount = computed(() => {
      return clipsStore.clips.length;
    });

    const durationTime = computed(() => {
      const minutes = Math.floor(recorder.duration / 60);
      var seconds = recorder.duration - minutes * 60;
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")
      );
    });

    function isNearEnd(num: number) {
      if (num > process.env.VUE_APP_RECORDER_MAX_DURATION - 11) {
        return true;
      }
      return false;
    }

    function loudnessBarHeight(height: number) {
      let maxLoudness = Math.max(...loudnessWaveform.value);
      let normalizedLoudness = Math.round(
        mapRange(height, 0, maxLoudness, 3, 50)
      );
      return normalizedLoudness;
    }

    if (!navigator.mediaDevices?.getUserMedia) {
      console.log("getUserMedia not supported on your browser!");
      toast({
        message: "Bitte Zugriff auf Mikrofon erlauben",
        color: "danger",
      });
    } else {
      const constraints = {
        audio: true,
        video: false,
      };
      let chunks = [];

      let onSuccess = (stream: MediaStream) => {
        mediaStream.value = stream;
        try {
          const types = [
            "audio/webm;codecs=opus",
            "audio/webm",
            "audio/mpeg",
            "audio/mpeg;codecs=mp3",
            "audio/mp3",
            "audio/mp4",
            "audio/mp4;codecs=aac",
            "audio/aac",
            "audio/x-m4a",
            "audio/mpeg",
            "audio/opus",
            "audio/ogg",
            "audio/wav",
            "audio/3gpp",
            "audio/3gpp2",
          ];
          console.groupCollapsed("Type Support");
          for (let i in types) {
            console.log(
              types[i],
              MediaRecorder.isTypeSupported(types[i]) ? "YES" : "NO"
            );
          }
          console.groupEnd();
          let canSupportMime = false;
          if (!MediaRecorder.isTypeSupported) {
            // Apple iPhone
            recorder.options.mimeType = "audio/mp4";
            canSupportMime = true;
            console.log(
              "Recording mit " +
                recorder.options.mimeType +
                ", " +
                recorder.options.audioBitsPerSecond +
                " (iPhone)"
            );
          } else {
            for (let i in types) {
              if (MediaRecorder.isTypeSupported(types[i])) {
                recorder.options.mimeType = types[i];
                canSupportMime = true;
                break;
              }
            }
            if (canSupportMime) {
              console.log(
                "Recording mit " +
                  recorder.options.mimeType +
                  ", " +
                  recorder.options.audioBitsPerSecond
              );
            } else {
              console.log(
                "Browser unterstützt kein MediaRecorder.isTypeSupported / alle Mime. Recording mit default options"
              );
            }
          }
          if (canSupportMime) {
            mediaRecorder.value = new MediaRecorder(stream, recorder.options);
          } else {
            mediaRecorder.value = new MediaRecorder(stream);
          }

          mediaRecorder.value.ondataavailable = (e) => {
            chunks.push(e.data);

            let reader = new FileReader();
            reader.onloadend = () => {
              //console.log(reader.result);
              let fileid = dayjs().format("YYYY-MM-DD-HH-mm-ss");
              let filename = "Recording #" + (clipsCount.value + 1);
              let clip = {
                id: fileid,
                name: filename,
                audio: reader.result,
                duration: recorder.duration,
                user: "",
              };
              recorder.duration = 0;

              clipsStore.saveClip(clip).then(() => {
                clipsStore.clips.unshift(clip);
              });
            };
            reader.readAsDataURL(e.data);
          };
        } catch (e) {
          console.log(e);
        }
      };

      let onError = (err: string) => {
        console.log("navigator.mediaDevices.getUserMedia: " + err);
        toast({
          message: "Bitte Zugriff auf Mikrofon erlauben",
          color: "danger",
        });
      };

      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(onSuccess, onError);
      } else {
        console.log("navigator.mediaDevices.getUserMedia nicht verfügbar");
        toast({
          message: "Bitte Zugriff auf Mikrofon erlauben",
          color: "danger",
        });
      }
    }

    function visualize(stream: MediaStream) {
      const audioCtx = new AudioContext();
      const source = audioCtx.createMediaStreamSource(stream);

      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount; // = analyser.fftSize / 2
      const dataArray = new Uint8Array(bufferLength);

      source.connect(analyser);

      let last = 0;
      let draw = (now: number) => {
        canvasInterval.value = requestAnimationFrame(draw);

        if (!last || now - last >= 1 * 1000) {
          last = now;
          analyser.getByteFrequencyData(dataArray); // 0 - 255

          let loudness =
            dataArray.reduce((prev, cur) => prev + cur, 0) / bufferLength;

          loudnessWaveform.value.push(loudness);
        }
      };

      draw(0);
    }

    function stopRecorder() {
      if (mediaRecorder.value) {
        cancelAnimationFrame(canvasInterval.value);
        clearInterval(recorder.interval);
        mediaRecorder.value.stop();
        loudnessWaveform.value.splice(0);
        //console.log(this.mediaRecorder.state);
        recorder.recording = false;
        //console.log("recorder stopped");
      }
    }

    function record() {
      // TODO: play/pause Stream & Recordings
      if (recorder.recording) {
        stopRecorder();
      } else {
        if (mediaRecorder.value) {
          recorder.duration = 0;
          mediaRecorder.value.start();
          recorder.recording = true;
          if (mediaStream.value) {
            visualize(mediaStream.value);
          }
          recorder.interval = setInterval(() => {
            recorder.duration++;
            if (recorder.duration > process.env.VUE_APP_RECORDER_MAX_DURATION) {
              stopRecorder();
            }
          }, 1000);
        }
      }
    }
    return {
      sender,
      recorder,
      loudnessWaveform,
      loudnessBarHeight,
      record,
      isNearEnd,
      durationTime,
      clipsCount,
    };
  },
});
