
import { defineComponent, ref, onMounted } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonList,
} from "@ionic/vue";
import TakesListEmpty from "@/components/TakesListEmpty.vue";
import TakesListClip from "@/components/TakesListClip.vue";
import { hourglassOutline, cloudUploadOutline } from "ionicons/icons";

import { useClipsStore } from "@/stores/clips";

export default defineComponent({
  name: "TakesPage",
  components: {
    TakesListEmpty,
    TakesListClip,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonCol,
    IonGrid,
    IonRow,
    IonList,
    IonContent,
    IonPage,
  },
  setup() {
    const takesSegment = ref("draft");
    const clipsStore = useClipsStore();

    onMounted(() => {
      clipsStore.getClips();
      // setTimeout(() => {
      //   clipsStore.getClips();
      // }, 1000);
    });

    return {
      clipsStore,
      takesSegment,
      hourglassOutline,
      cloudUploadOutline,
    };
  },
});
