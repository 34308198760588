
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonRow,
  IonText,
  IonButton,
  actionSheetController,
  alertController,
  modalController,
} from "@ionic/vue";
import { ellipsisHorizontalOutline, cloudUploadOutline } from "ionicons/icons";
import { defineComponent, PropType, ref } from "vue";
import { Clip } from "@/services/interfaces";
import { clipdate, clipduration } from "@/utils/helpers";
import PushModal from "@/components/PushModal.vue";

export default defineComponent({
  name: "TakesListClip",
  props: {
    clip: Object as PropType<Clip>,
  },
  components: {
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonNote,
    IonRow,
    IonText,
    IonButton,
  },
  setup(props) {
    const showAudio = ref<string | undefined | null>();

    const presentClipOptions = async () => {
      const actionSheet = await actionSheetController.create({
        header: props.clip?.name,
        buttons: [
          {
            text: "Umbenennen",
            handler: () => presentClipRename(),
          },
          {
            text: "Löschen",
            role: "destructive",
            handler: () => presentClipDelete(),
          },
          {
            text: "Abbrechen",
            role: "cancel",
            data: {
              action: "cancel",
            },
          },
        ],
      });

      await actionSheet.present();
    };

    const presentPushModal = async () => {
      const modal = await modalController.create({
        component: PushModal,
        componentProps: {
          open: true,
          clip: props.clip,
        },
        presentingElement: undefined,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      console.log(data, role);
    };

    const presentClipRename = async () => {
      const alert = await alertController.create({
        header: "Take umbenennen",
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
          },
          {
            text: "OK",
            role: "confirm",
            handler: (el) => {
              // TODO: save
              console.log(el.clipname);
            },
          },
        ],
        inputs: [
          {
            placeholder: "Name",
            value: props.clip?.name,
            name: "clipname",
            attributes: {
              minLength: 1,
              autofocus: true,
            },
          },
        ],
      });

      await alert.present();
    };

    const presentClipDelete = async () => {
      const alert = await alertController.create({
        header: "Bist du sicher, dass du diesen Take löschen möchtest?",
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
          },
          {
            text: "Löschen",
            role: "destructive",
            handler: () => {
              // TODO: delete
              console.log("delete");
            },
          },
        ],
      });

      await alert.present();
    };

    return {
      showAudio,
      ellipsisHorizontalOutline,
      cloudUploadOutline,
      clipdate,
      clipduration,
      presentClipOptions,
      presentPushModal,
      presentClipRename,
      presentClipDelete,
    };
  },
});
