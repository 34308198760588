import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c0bd50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_takes_list_clip = _resolveComponent("takes-list-clip")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_TakesListEmpty = _resolveComponent("TakesListEmpty")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        collapse: "fade",
        translucent: true,
        class: "ion-no-border"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Takes")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Takes")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_segment, {
                        modelValue: _ctx.takesSegment,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.takesSegment) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_segment_button, {
                            value: "draft",
                            layout: "icon-start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Entwürfe")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.hourglassOutline,
                                class: "ion-margin-end"
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_segment_button, {
                            value: "pushed",
                            layout: "icon-start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Gepusht")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.cloudUploadOutline,
                                class: "ion-margin-end"
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.takesSegment == 'draft')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.clipsStore.clips.length)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_TransitionGroup, {
                          name: "list",
                          mode: "out-in",
                          tag: "div",
                          class: "list"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clipsStore.clips, (clip) => {
                              return (_openBlock(), _createBlock(_component_takes_list_clip, {
                                clip: clip,
                                key: clip.id
                              }, null, 8, ["clip"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_TakesListEmpty, {
                      key: 1,
                      segment: "draft"
                    }))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.clipsStore.clips.filter((x) => x.pushed).length)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_TransitionGroup, {
                          name: "list",
                          mode: "out-in",
                          tag: "div",
                          class: "list"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clipsStore.clips.filter((x) => x.pushed), (clip) => {
                              return (_openBlock(), _createBlock(_component_takes_list_clip, {
                                clip: clip,
                                key: clip.id
                              }, null, 8, ["clip"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_TakesListEmpty, {
                      key: 1,
                      segment: "pushed"
                    }))
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}