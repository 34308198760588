import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { createPinia } from "pinia";

import { IonicVue } from "@ionic/vue";

const pinia = createPinia();

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import de from "dayjs/locale/de";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.locale(de);
dayjs.updateLocale("de", {
  calendar: {
    lastDay: "[Gestern,] HH:mm",
    sameDay: "[Heute,] HH:mm",
    nextDay: "[Morgen,] HH:mm",
    lastWeek: "[Letzter] dddd[,] HH:mm",
    nextWeek: "dddd[,] HH:mm",
    sameElse: "D. MMMM YYYY - HH:mm",
  },
});

import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const app = createApp(App).use(IonicVue).use(router).use(pinia);

router.isReady().then(() => {
  app.mount("#app");
});
