
import {} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TakesListEmpty",
  props: {
    segment: String,
  },
  components: {},
});
