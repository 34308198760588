
import { IonNav } from "@ionic/vue";
import { defineComponent, PropType, onMounted } from "vue";
import { Clip } from "@/services/interfaces";
import PushModalTime from "./PushModalTime.vue";

import { useStreamsStore } from "@/stores/streams";

export default defineComponent({
  name: "PushModal",
  props: {
    clip: Object as PropType<Clip>,
  },
  components: {
    IonNav,
  },
  setup() {
    const streamsStore = useStreamsStore();

    onMounted(() => {
      streamsStore.getSchedules();
    });

    return {
      PushModalTime,
    };
  },
});
