
import {
  IonApp,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonFooter,
  IonToolbar,
  IonRouterOutlet,
} from "@ionic/vue";
import { defineComponent } from "vue";
import RecordSheet from "@/components/RecordSheet.vue";
import { micOutline, walletOutline, optionsOutline } from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonFooter,
    IonToolbar,
    RecordSheet,
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    return {
      micOutline,
      walletOutline,
      optionsOutline,
    };
  },
});
